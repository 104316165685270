// 关联数据取值关联导出mixin
import {
  AssignmentOptions, BusinessRelationType, BusinessFieldType, BusinessType
 } from "@/components/businessDetail/data/BusinessCommonHeader";
 import SubsetColumn from "@/components/businessDetail/data/columns/subSet/SubsetColumn";
 import { newTabAddBusinessData } from "@/components/businessDetail/helper";
 import { detailListService, btnService } from "@/api/businessModel";
 import Utils from "@/views/businessModel/list/data/utils";

 const {
  basic, business, attend, manageModel
 } = BusinessRelationType;

 export default {
   methods: {
     async checkCanAdd() {
       const { column, businessData } = this;
       const isRelationData = column.fieldType === BusinessFieldType.associatedData;
       if ((isRelationData && column.inputConfig && column.inputConfig.length) || (column.dataChanges && column.dataChanges.length)) {
         const businessParams = businessData.paramsForSubmit();
         const params = {
           saveType: "1",
           checkRelationAddRange: "1",
           relationAddDataRanges: isRelationData ? JSON.stringify(column.inputConfig) : JSON.stringify(column.dataChanges),
           inputHints: isRelationData ? column.inputHints : column.subTitle,
           templateId: businessData.mdInfo.templateId,
           version: businessData.mdInfo.version,
           mdData: businessParams.mdData,
           mdEditField: businessParams.mdEditField
         };
         console.log("checkCanAdd");
         const res = await btnService.mdSubmitData(params);
         if (res.checkRelationResult === "1") {
           this.addAssociatedRow();
         } else {
           this.$message.error(column.inputHints || res.inputHints);
         }
       } else {
         this.addAssociatedRow();
       }
     },
     async addAssociatedRow() {
        // 数据取值
       const params = {};
       const { column, businessData } = this;
       const businessParams = JSON.parse(businessData.paramsForSubmit().mdData);
       console.log(businessParams);
       const { checkRule } = column;
       checkRule.forEach((rule) => {
         params[rule.targetUuid] = businessParams[rule.sourceField];
       });

       if (column.relationType === business) { // 业务建模
         newTabAddBusinessData({ templateId: column.relationData, fillFields: JSON.stringify(params) });
         window.addEventListener("storage", this.businessDetail_businessData);
       } else if (column.relationType === basic) { // 基础数据
         newTabAddBusinessData({
           objectTypeId: column.relationData, templateId: column.relationData, businessType: BusinessType.pureBasic, fillFields: JSON.stringify(params)
         });
         // 基础数据页签增加数据的回调 点击新增才监听, 拿到值之后移除监听.
         window.addEventListener("storage", this.businessDetail_baseData);
       }
     },
     // 导出关联显示
     async exportRelationDataByView() {
       this.exportRelationDataByAdd({});
     },
     // 导出关联添加
     async exportRelationDataByAdd(params) {
       const {
         relationType, field, version, templateDataId, relationData, relationRange, nameVariable, objectTypeId, relationCondition
       } = this.column;
       const templateId = this.column.templateId || this.$route.query.templateId;
       this.exportBtnLoading = true;
       const mdData = this.businessData.mdData.originData || this.businessData.mdData;
       const relationSet = relationCondition.reduce((acc, item) => {
           if (item.version === mdData.version) {
             acc[item.targetField] = item.tparentField && mdData[item.tparentField][0] ? mdData[item.tparentField][0][item.targetField]
               : mdData[item.targetField];
           }
           return acc;
         }, {});
       // 业务建模
       if (relationType === business) {
         const res = await detailListService.mdRelationDataExport({
           ...params,
           controlTempId: templateId,
           controlField: field || nameVariable,
           controlVersion: version, // 用数据的版本
           parentId: "0",
           commonSet: JSON.stringify({ relationSet }),
           templateId: templateDataId || relationData,
           area: 1,
           requestType: "stream",
           assignFlag: 2
         });
         Utils.exportData(res);
       }
       // 基础数据 考勤 管理模型
       if ([basic, attend, manageModel].includes(relationType)) {
         const res = await detailListService.mdRelationBaseDataExport({
           ...params,
           controlType: 1,
           relationType,
           relationRange,
           requestType: "stream",
           templateId: relationData || templateDataId,
           commParams: JSON.stringify({
             relationSet: {},
             controlField: nameVariable || field,
             controlVersion: version, // 用数据的版本
             controlTempId: templateId || objectTypeId
           })
           // screen: "4"
         });
         Utils.exportData(res);
       }
       this.exportBtnLoading = false;
     },
     async querySubDataList(pageSize = 10) {
       const {
         field, relationData, version, relationType, nameVariable, objectTypeId, relationRange, templateDataId, relationCondition
       } = this.column;
       const templateId = this.column.templateId || this.$route.query.templateId;
       const mdData = JSON.parse(this.businessData.paramsForSubmit().mdData);
       const relationSet = relationCondition.reduce((acc, item) => {
         acc[item.targetField] = mdData[item.targetField];
         return acc;
       }, {});
       const commParams = {
         relationSet: {},
         controlField: nameVariable,
         controlVersion: version,
         controlTempId: templateId || objectTypeId
       };
       const params = {
         relationRange,
         relationType,
         templateId: relationData,
         commParams: JSON.stringify(commParams)
       };
       // eslint-disable-next-line no-async-promise-executor
       return new Promise(async(resolve) => {
         let result = {};
         if (Number(relationType) === business) {
           if (!templateDataId) return;
           // 业务建模
           result = await detailListService.mdQuerySubDataList({
             pageSize,
             pageNo: 1,
             controlTempId: templateId,
             controlField: field,
             controlVersion: version,
             parentId: 0,
             schemeVersionId: "",
             templateId: relationData || templateDataId,
             ids: "",
             area: 1,
             commonSet: JSON.stringify({ relationSet })
           });
         } else if (Number(relationType) === basic || Number(relationType) === attend) {
           // 基础数据
           if (!relationData) return;
           result = await detailListService.mdQueryNewRelationDataList(params);
         }
         resolve(result);
       });
     },
     fillRelationField(rowData) {
       const { mappingRule } = this.column;
       const firstRow = rowData || this.column.columnValue[0];
       if (firstRow) {
         mappingRule.forEach((rule) => {
           const targetColumn = this.column.findMainAreaFieldByUuid(rule.targetUuid);
           // 文本回填
           if (targetColumn.fieldType === BusinessFieldType.inputCommon && typeof firstRow[rule.sourceField] === "object") {
             targetColumn.updateValueWithOptions(firstRow[rule.sourceField].content, AssignmentOptions.associatedData);
           } else {
             targetColumn.updateValueWithOptions(firstRow[rule.sourceField], AssignmentOptions.associatedData);
           }
         });
       }
     },
     // 关联取值关联多条
     fillRelationMultiField(list, reset) {
       const { mappingRule, field } = this.column;
       // 关联后最后提交数据需要把`${field}_relationData`字段加上去
       this.businessData.relationData = {
         ...this.businessData.relationData || {},
         [`${field}_relationData`]: list?.map((item) => ({
           id: item.mainId || item.id,
           detailId: item.detailId || item.id,
           itemDataId: item.detailId ? item.id : undefined
         }))
       };
       // 找明细列
       const findColumnByField = (field) => {
         let res = null;
         this.businessData.sections.forEach((section) => {
           section.areas.forEach((area) => {
             area.forEach((item) => {
               if (item.field === field) {
                 res = item;
               }
             });
           });
         });
         return res;
       };
       const sleep = (wait = 300) => new Promise((resolve) => {
           setTimeout(() => {
             resolve();
           }, wait);
         });
       mappingRule.forEach(async(rule) => {
         // 回填明细
         console.log(mappingRule, "mappingRule");

         if (rule.tparentField && rule.tparentField !== "mainTopArea") {
           const targetColumn = this.column.findMainAreaField(rule.tparentField) || findColumnByField(rule.tparentField);
           targetColumn.tableLoading = true;
           if (targetColumn.maxNum) {
             list = list.slice(0, targetColumn.maxNum);
           }
           await sleep();
           const subColumn = new SubsetColumn(targetColumn);
           const subDetail = targetColumn.subDetails[0] || subColumn.subDetails[0];
           if (reset) {
            console.log(reset, "reset");

             subDetail.fields.forEach((field) => {
               field.showValue = "";
               field.updateValueWithOptions("", AssignmentOptions.manual);
             });
             targetColumn.subDetails = [subDetail];
             return;
           }
           // 关联数据，暂时屏蔽
           // const linkedList = [];
           const values = [];
           list.forEach((data) => {
             const line = targetColumn.createOneLineData();
             const { lineData, mainData } = targetColumn.formatMappingRule(data);
             line.refData = {};
             line.refData.id = data.id;
             if (data.detailId) {
               line.refData.detailId = data.detailId;
             }
             if (data.itemDataId) {
               line.refData.itemDataId = data.itemDataId;
             }
             lineData.refData = line.refData;
             values.push(lineData);
             targetColumn.setLineValue(line, AssignmentOptions.manual, lineData);
             mainData.forEach((value, column) => {
               column.updateValueWithOptions(value, AssignmentOptions.manual);
             });
             // line.fields.forEach((field) => {
             //   if (field.field === rule.targetField) {
             //     const value = data[rule.sourceField];
             //     // 手动触发修改
             //     if (field.updateValueWithOptions) {
             //       // 地址需要组织一下数据
             //       field.updateValueWithOptions(value, AssignmentOptions.manual);
             //       // console.log(field)
             //       // console.log(field.field)
             //       // console.log(field.columnValue)
             //     }
             //   }
             // });
             // 关联数据，暂时屏蔽 test
             // linkedList.push(line);
           });
           // 关联数据，暂时屏蔽
           // targetColumn.subDetails = linkedList;
           console.log(12314, list);
           list.forEach((item, index) => {
             const line = targetColumn.subDetails[this.rowIndex];
             line.fields.forEach((field) => {
               const data = item;
               const rule = mappingRule.find((rule) => rule.targetField === field.field);
               if (rule) {
                 const { sourceField } = rule;
                 // 地址,单选需要组织一下数据
                 let value = "";
                 if (field.fieldType === BusinessFieldType.address) {
                   value = {
                     provinceId: data[`${sourceField}_PROVINCEID`],
                     provinceName: data[`${sourceField}_PROVINCENAME`],
                     cityId: data[`${sourceField}_CITYID`],
                     cityName: data[`${sourceField}_CITYNAME`],
                     districtId: data[`${sourceField}_DISTRICTID`],
                     districtName: data[`${sourceField}_DISTRICTNAME`],
                     address: data[`${sourceField}_ADDRESS`]
                   };
                 }
                 if (field.fieldType === BusinessFieldType.select && rule.targetField !== field.field) {
                   value = {
                    name: data.name,
                    id: data.id
                   };
                 } else {
                   value = data[sourceField];
                 }
                 console.log(field.name, "fieldfieldfieldfield", line, data, sourceField);

                 // 手动触发修改
                 if (field.updateValueWithOptions) {
                   field.updateValueWithOptions(value, AssignmentOptions.manual);
                 }
               }
             });
           });
           targetColumn.tableLoading = false;
           return;
         }
         // 回填主表字段
         list.forEach((firstRow) => {
           const targetColumn = this.column.findMainAreaFieldByUuid(rule.targetUuid);
           // 文本回填
          //  if (targetColumn?.fieldType === BusinessFieldType.inputCommon && typeof firstRow[rule.sourceField] === "object") {
          //    targetColumn.updateValueWithOptions(firstRow[rule.sourceField].content, AssignmentOptions.associatedData);
          //  } else {
             targetColumn?.updateValueWithOptions(firstRow[rule.sourceField], AssignmentOptions.associatedData);
          //  }
         });
       });
     }
   }
 };
