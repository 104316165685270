<!--
 * @Author: xujun
 * @Date: 2020-08-31 09:01:57
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-15 11:32:28
 * @Description: 附件展示
-->
<template>
  <div :class="['image-file','is-'+type, {
    'horizontal': direction ==='h',
    'vertical': direction ==='v'}]" >
    <div
      :class="['progress',{'progress100':progress > 98}]"
      :style="progressStyle"
    ></div>
    <div :class="['content-box', size]" :style="heightStyle">
      <div v-if="type === 'image'" class="image-box">
        <el-image :src="fileUrl" fit="fill" :class="['image',size]">
          <div slot="error" class="error">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <en-button v-if="deletable" icon="shanchu-liebiao" text icon-color="red" stop-propagation @click="handleDelete" class="delete"></en-button>
      </div>
      <div v-else-if="type === 'more'" class="more-box">
        <el-popover width="400" trigger="hover">
          <div slot="reference" :class="['more',size]">
            +{{ moreNum }}
          </div>
          <slot name="more"></slot>
        </el-popover>
      </div>
      <div v-else class="file-box">
        <div class="file-pre" @click="handleClickFile">
          <en-button text :icon="fileIconColor.icon" :icon-color="fileIconColor.color" icon-size="18"></en-button>
          <span :class="['file',size]" :title="file.name">{{ file.name }}</span>
        </div>
        <en-button v-if="downloadable" icon="weibiaoti-1" text icon-color="gray" stop-propagation @click="handleDownload" class="download" title="下载"></en-button>
        <en-button v-if="deletable" icon="guanbi-danchuang" text icon-color="gray" stop-propagation @click="handleDelete" class="delete" title="删除"></en-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from "@/tools/getFileUrl";
import { openOfficeOnline } from "@/tools/util";

export default {
  name: "ImageFile",
  data() {
    return {
    };
  },
  inject: ["$attachment"],
  props: {
    height: [String, Number], // 高度
    moreNum: Number, // 隐藏个数
    direction: String, // 方向 h/v (h横线 v纵向, 默认无方向无布局)
    type: { // 文件类型
      type: String,
      default: "file"// file / image /  more
    },
    size: { // 大小
      type: String,
      default: "mini"// mini / small /  medium
    },
    data: Object, // 数据
    deleteMethod: Function, // 删除函数
    hasDownload: {
      type: Boolean,
      default: false
    },
    hasDelete: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    file() {
      return this.data || {};
    },
    fileIconColor() {
      let icon;
      let color = "#4794e2";
      const extension = this.file.extension?.toLowerCase();
      switch (extension) {
        case "pdf":
          icon = "fujian-pdf";
          color = "#f76b6e";
          break;
        case "doc":
        case "docx":
          icon = "fujian-Word";
          break;
        case "ppt":
        case "pptx":
          icon = "fujian-PPT";
          color = "#f7c028";
          break;
        case "xls":
        case "xlsx":
          icon = "fujian-excel";
          color = "#57CC7D";
          break;
        case "zip":
        case "rar":
          icon = "fujian-yasuobao";
          break;
        case "txt":
          icon = "fujian-txt";
          break;
        default:
          if (Number(this.file.dataType) === 0) {
            icon = "gaoqingtupian";
            color = "yellow";
          } else {
            icon = "fujian-qita";
          }
          break;
      }
      return { icon, color };
    },
    fileUrl() {
      const {
 code, type, name, url
} = this.file;
      console.log(code, type, name, "code, type, name");
      return url; // getFileUrl(code, type, name);
    },
    progress() {
      const { progress, status } = this.file;
      return status === "ready" || status === "uploading" ? Math.max(Math.min(Number(progress) || 0, 100), 0) : 100;
    },
    deletable() {
      return this.$attachment.mode === "edit" && this.hasDelete;
      // return !!this.deleteMethod;
      // const { fileData, status } = this.file;
      // return this.deleteMethod && (fileData || status === "fail");
    },
    downloadable() { // 是否有下载权限
      const { mdConfig } = this.$attachment;
      return Number(mdConfig.isDownload) === 0 && this.hasDownload;
    },
    progressStyle() {
      const style = {};
      // 颜色
      if (status === "fail") {
        style.backgroundColor = "#f3ebef";// 红色
      } else if (status === "uploading") {
        style.backgroundColor = "#e2eef8";// 深蓝色
      } else {
        style.backgroundColor = (this.file.fileData || this.type === "more") ? "#f4f9fc" : "#e7faf5";// 浅蓝色(本地) 绿色(网络)
      }

      // 进度
      style.width = `${this.progress}%`;
      return style;
    },
    heightStyle() {
      return this.height ? `height: ${this.height}px` : undefined;
    }
  },
  methods: {
    handleClickFile() {
      const { extension, code } = this.file;
      const { isEdit, isAdd } = this.$attachment.mdConfig;
      if (["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf"].includes(extension)) {
        let operType = 1;
        if (this.mode === "edit" && (isEdit === 0 || isAdd === 0)) {
          operType = 2;
        }
        // 这4种格式文件仅支持查看
        if (["doc", "ppt", "xls", "pdf"].includes(extension)) {
          operType = 1;
        }

        openOfficeOnline(operType, extension, code);
        return;
      }

      this.$message.warning("不支持用officeOnline打开的类型");
    },
    // 删除
    handleDelete() {
      if (this.deleteMethod) {
        this.deleteMethod(this.data);
      }
    },
    // 下载
    handleDownload() {
      window.open(this.file.url);
    }
  }
};
</script>

<style scoped lang="scss">

  $size-mini: 26px;
  $size-small: 80px;
  $size-medium: 70px;
  $border-radius: 5px;

  //文件(不需要宽度)
  .is-file {
    padding: 0 8px;
    margin: 0 3px;
    .mini {
      height: $size-mini;
    }
    .small {
      height: $size-small;
    }
    .medium {
      height: $size-medium;
    }
  }
  //图片样式
  .is-image,.is-more {
    .mini {
      width: $size-mini;
      height: $size-mini;
    }
    .small {
      width: $size-small;
      height: $size-small;
    }
    .medium {
      width: $size-medium;
      height: 100px;
    }
  }
  .horizontal {
    display: inline-block;
    &.image-file + &.image-file {
      margin-left: 3px;
    }
  }
  .vertical {
    &.image-file + &.image-file {
      margin-top: 11px;
    }
  }

  .files>.image-file{
    max-width: 180px;
  }

  .image-file {
    border: 1px solid #e6edfc;
    border-radius: $border-radius;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: #a2c9ef;
    }

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      pointer-events: none;
      transition: 200ms;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    .progress100 {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .content-box {
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .file-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        font-size: 12px;
        .file-pre {
          display: flex;
          align-items: center;
          width: 92%;
        }

        .file {
          text-align: left;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          height: 100%;
        }
        .delete,.download {
          visibility: hidden;
        }
        &:hover {
          .delete,.download {
            visibility: visible;
          }
        }
      }
      .image-box {
        position: relative;
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $border-radius;
        }
        .delete {
          position: absolute;
          float: right;
          top: 0;
          right: 3px;
          min-height: 20px;
          // display: none;
          visibility: hidden;
        }
        &:hover {
          .delete {
            // display: block;
            visibility:visible;
          }
        }
      }
      .more-box {
        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          .more-btn {
            width: 20px;
            height: 20px;
            color: #A8B5C6;
            outline: none;
          }
        }
      }
    }
  }
</style>
