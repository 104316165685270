<template>
  <div :class="['attachment-box', border || !isReadOnly ? 'border' : '', fixedHeight ? 'fixedHeight' : '']">
    <!--本地文件上传-->
    <en-upload v-if="hasOnlyLocalPermisson && uploadVisible" :on-change="handleUploadChange"
      :on-progress="handleProgressChange"
      @error="handleError"
      :on-exceed="handleExceed" :file-list="fileList" :limitNum="limitNum"
      :limitType="mdConfig.limitType || 1" class="add-btn-box">
    </en-upload>
    <span v-if="isUploadStatus" class="logind-box"></span>
    <!-- 弹框 文件上传-->
    <div v-if="hasMorePermisson" class="add-btn-box">
      <en-button iconColor="#5694df" text type="blue" :icon="mdConfig.limitType=== 2 ? 'iconjintupianleixing':'fujianhetupian-kongjian'" icon-size="18"
        @click="$refs.mainDialog.open()">{{ mdConfig.limitType=== 2 ? '上传图片' : '上传附件' }}</en-button>
      <main-dialog ref="mainDialog" @change="handleAddNewFiles"></main-dialog>
    </div>
    <!-- 文件样式 -->
    <div v-if="mdConfig.enclosureShowStyle === 5" class="icon-box">
      <div v-if="allImageList.length" style="display:flex">
        <div v-for="(item, index) in allImageList" :key="index">
          <div class="annex" v-if="item.type === '000' && index < 2" @click.stop="$refs.preview.open()">
            <div class="annex-icon">
              <en-icon
              name="icontupian"
              size="small"
              color="#FFA32B"
            ></en-icon>
            </div>
            <div class="annex-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="file-other" v-if="allImageList.length > 2">+{{ allImageList.length - 2 }}</div>

      </div>
      <!-- 附件  -->
      <div v-if="allFileList.length" style="display:flex">
        <div v-for="(item, index) in allFileList" :key="index">
          <div class="annex" v-if="item.type === '001' && index < 2" @click.stop="$refs.preview.open()">
            <div class="annex-icon">
              <en-icon :name="fileIcon[item.extension].icon" :color="fileIcon[item.extension].color" size="small"
                style="margin-left:5px" class="icon"></en-icon>
            </div>
            <div class="annex-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="file-other" v-if="allFileList.length > 2">+{{ allFileList.length - 2 }}</div>
      </div>
    </div>
    <!-- 小图标样式 -->
    <div v-if="mdConfig.enclosureShowStyle === 2" class="icon-box">
      <!--  图标 -->
      <en-button v-if="allImageList.length && mdConfig.enclosureShowStyle === 2" icon="gaoqingtupian" icon-size="18" text
        @click.native.stop="$refs.preview.open()">({{ allImageList.length }})</en-button>
      <en-button v-if="allFileList.length && mdConfig.enclosureShowStyle === 2" icon="fujianhetupian-kongjian" icon-size="18" text
        @click.native.stop="$refs.preview.open()">({{ allFileList.length }})</en-button>
    </div>
    <!-- 仅图片 缩略图片各种格式-->
    <div v-else-if="mdConfig.limitType === 3 && [1, 3, 4].includes(mdConfig.enclosureShowStyle)" class="files">
      <image-file v-for="(file, index) in showList" ref="imgFileMod" :key="file.id || file.uid || index" :data="{ ...file }"
        :type="file.type === '000' || file.dataType === '000' ? 'image' : 'file'" :size="newFileSize" class="file" direction="h"
        :delete-method="handleDelete" @click.native.stop="handleClickImage(index)">
      </image-file>
      <!-- 更多-->
      <image-file type="more" v-if="moreList.length" :size="newFileSize" :moreNum="moreList.length" direction="h">
        <template #more>
          <div class="more-list">
            <image-file v-for="(file, index) in moreList" :data="{ ...file }" :key="file.id || file.uid || index"
              class="file" direction="v" :delete-method="handleDelete"
              @click.native.stop="handleClickImage(index + showList.length)">
            </image-file>
          </div>
        </template>
      </image-file>
    </div>
    <!-- 都不满足 -->
    <div v-else class="icon-box">
      <!-- {{ allImageList }} -->
      <div v-if="allImageList.length" style="display:flex">

          <div v-for="(item, index) in allImageList" :key="index">
            <!-- 详情内 -->
            <div class="annex" v-if="item.type === '000' && index < 2 && !isTableCell" @click.stop="$refs.preview.open()">
              <div class="annex-icon">
                <en-icon
                name="icontupian"
                size="small"
                color="#FFA32B"
                style="margin-left:5px"
              ></en-icon>
              </div>
              <div class="annex-name">{{ item.name }}</div>
            </div>
            <!-- 表格内 -->
            <div class="annex" v-if="item.type === '000' && index < 1 && isTableCell" @click.stop="$refs.preview.open()">
              <div class="annex-icon">
                <en-icon
                style="margin-left:5px"
                name="icontupian"
                size="small"
                color="#FFA32B"
              ></en-icon>
              </div>
              <div class="annex-name">{{ item.name }}</div>
            </div>
          </div>
        <div class="file-other" v-if="allImageList.length > 2 && !isTableCell">+{{ allImageList.length - 2 }}</div>
        <div class="file-other" v-if="allImageList.length > 1 && isTableCell">+{{ allImageList.length - 1 }}</div>
      </div>
      <!-- 附件  -->
      <div v-if="allFileList.length" style="display:flex">
        <div v-for="(item, index) in allFileList" :key="index">
          <div class="annex" v-if="item.type === '001' && index < 2 && !isTableCell" @click.stop="$refs.preview.open()">
            <div class="annex-icon">
              <en-icon :name="fileIcon[item.extension].icon" :color="fileIcon[item.extension].color" size="small"
                style="margin-left:5px" class="icon"></en-icon>
            </div>
            <div class="annex-name">{{ item.name }}</div>
          </div>
          <div class="annex" v-if="item.type === '001' && index < 1 && isTableCell" @click.stop="$refs.preview.open()">
            <div class="annex-icon">
              <en-icon :name="fileIcon[item.extension].icon" :color="fileIcon[item.extension].color" size="small"
                style="margin-left:5px" class="icon"></en-icon>
            </div>
            <div class="annex-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="file-other" v-if="allFileList.length > 2 && !isTableCell">+{{ allFileList.length - 2 }}</div>
        <div class="file-other" v-if="allFileList.length > 1 && isTableCell">+{{ allFileList.length - 1 }}</div>
      </div>
    </div>
    <preview-dialog ref="preview" :file-list.sync="fileList" :title="mdConfig ? mdConfig.name : undefined" :mode="mode"
      :mdConfig="mdConfig" @handleDelete="handleDelete"></preview-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { fileService } from "@/api/businessModel";
import { getFileUrl } from "@/tools/getFileUrl";
import ImageFile from "./views/imageFile";
import MainDialog from "./dialog/mainDialog";
import PreviewDialog from "./dialog/previewDialog";
import Data from "./data/data";
import Index from "./data/utils";

export default {
  name: "EnAttachment",
  components: { ImageFile, MainDialog, PreviewDialog },
  mixins: [Data],
  provide() {
    return { $attachment: this };
  },
  data() {
    return {
      isUploadStatus: false,
      visible: false,
      maxShowNum: 3, // 测试
      fileList: [],
      fileIcon: {
        txt: { icon: "fujian-txt", color: "#21A3E6" },
        xls: { icon: "fujian-excel", color: "#4AAE2D" },
        xlsx: { icon: "fujian-excel", color: "#4AAE2D" },
        docx: { icon: "fujian-Word", color: "#537CE7" },
        doc: { icon: "fujian-Word", color: "#537CE7" },
        ppt: { icon: "fujian-PPT", color: "#FB7A26" },
        pdf: { icon: "fujian-pdf", color: "#EC1C32" }
      }
    };
  },
  props: {
    data: Array, // 原始已存在的数据
    column: {
      type: Object,
      default() {
        return {};
      }
    },
    isTableCell: { // 是否是在表格内
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    mode: {
      // 显示模式 (edit show)
      type: String,
      default: "show"
    },
    // NSInteger limitType; //限制类型，1附件和图片、2仅附件、3仅图片。默认0无配置。
    // NSString *limitNum;//按照输入框的数据来，如果没有设置则默认是99个。
    // BOOL isPhotograph;//仅允许拍照上传 0是 1否
    // BOOL isWatermark;//拍照上传添加水印  0是 1否
    // NSInteger limitNumType;//附件数量1 不限 2 单个 3 自定义 默认1不限
    // NSInteger enclosurePathType;//附件路径类型 1:本地上传、2:我的文件夹
    // NSInteger produceType;//1.自动生成 2.在线创建 3手工上传 默认3
    // BOOL wordCreateFlag;//创建标识:0勾选 1未勾选
    // BOOL templateCreateFlag;//模板创建标识:0勾选 1未勾选
    // NSInteger enclosureShowStyle;///附件显示样式 1:缩略图 2:图标 3:正方形 4：长方形 5：文件
    // BOOL enclosurePathLocalType;//本地上传 0勾选 1未勾选
    // BOOL enclosurePathFileType;//我的文件夹 0勾选 1未勾选
    // NSInteger enlargeStyle;//0:无1:正方形 2:长方形
    mdConfig: Object, // 业务建模附件配置
    busData: Object, // 当前业务建模整体数据
    displayGrid: { // 是否是列表展示
      type: Boolean,
      default: false
    },
    calcMaxShowNum: { // 是否计算可展示最大值
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        if (this.calcMaxShowNum) {
          this.maxShowNum = 999;
        }

        this.fileList = [].concat(data);
        const { enclosureShowStyle } = this.mdConfig;
        // 非图标模式 但文件数量大于1 在列表上以图标模式展示
        if (this.displayGrid && Number(enclosureShowStyle) === 2 && this.fileList.length > 1) {
          this.isIcon = true;
        }

        // maxShowNum 在缩略图模式下需要通过计算得出
        this.$nextTick(() => {
          if ([1, 3, 4].includes(Number(enclosureShowStyle)) && this.calcMaxShowNum) {
            const imgMod = this.$refs.imgFileMod;
            if (!imgMod) return;
            let _w = 0;
            const attachBox = document.querySelector(".attachment-box");
            let aw = attachBox.clientWidth - 71;
            if (this.moreList.length) {
              // 更多的div
              aw -= 31;
            }
            for (let i = 0; i < imgMod.length; i++) {
              const w = imgMod[i].$el.offsetWidth + 6;
              _w += w;
              if (_w > aw) {
                this.maxShowNum = i;
                break;
              }
            }
            if (_w < aw) {
              this.maxShowNum = imgMod.length;
            }
          }
        });
      }
    }
  },
  computed: {
    newFileSize() {
      console.log(this.mdConfig.enclosureShowStyle, "this.mdConfig.enclosureShowStyle");
      return this.mdConfig.enlargeStyle === 3 ? "medium" : this.mdConfig.enlargeStyle === 2 ? "small" : "mini";
    },
    showList() {
      return this.fileList.slice(0, this.maxShowNum).filter((item) => item);
    },
    moreList() {
      return this.fileList.length > this.maxShowNum ? this.fileList.slice(this.maxShowNum) : [];
    },
    allImageList() {
      const arr = Index.getImageList(this.fileList);
      // arr.forEach((itm) => {
      //   // if (!itm.url) {
      //   // itm.url = getFileUrl(itm.fileCode || itm.code, itm.type || itm.dataType, itm.name);
      //   // }
      // });
      return arr;
    },
    allFileList() {
      return Index.getFileList(this.fileList);
    },
    fixedHeight() { // 当不是缩略图的时候 固定高度
      return (this.fileList.length && ![1, 2, 5].includes(this.mdConfig.enclosureShowStyle));
    },
    uploadVisible() {
      const { limitNumType, limitNum } = this.mdConfig;
      if (this.data.length === 1 && Number(limitNumType) === 2) { // 数量限制单个
        return false;
      }
      if (Number(limitNumType) === 3) { // 自定义
        if (this.data.length >= Number(limitNum)) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    handleUploadChange({ fileList, isAllUpload }) {
      this.isUploadStatus = true;
      if (this.checkLimitNum(fileList.length)) {
        return;
      }
      this.handleAddNewFiles(fileList, isAllUpload);
    },
    handleProgressChange() {
      this.fileList.splice(this.fileList.length);
    },
    handleError(file) {
      if (file.status === "fail") {
        this.$message.error("上传失败");
      }
    },
    async handleAddNewFiles(fileList, isAllUpload = true) {
      // 处理我的文件夹 选择的文件
      const folderFile = fileList.filter((obj) => Object.prototype.hasOwnProperty.call(obj, "folderId"));
      const folderCode = [];
      folderFile.forEach((file) => {
        folderCode.push(file.fileUrl);
      });
      if (folderCode.length) {
        const res = await fileService.replaceFileCode({
          fileCodeList: JSON.stringify(folderCode)
        });
        if (!res.data) {
          let msg = res.msg;
          if (msg === "文件已删除或者地址已经改变") {
            msg = "模板文件已删除或者地址已经改变，请联系管理员";
          }
          this.$message.error(msg);
          return;
        }

        folderFile.forEach((itm) => {
          itm.fileCode = res.data[itm.fileUrl];
          itm.code = itm.fileCode;

          const index = fileList.findIndex((f) => f.fileUrl === itm.fileUrl);
          fileList.splice(index, 1, itm);
        });
      }

      fileList.forEach((itm) => {
        itm.type = itm.type || itm.dataType;
      });
      this.fileList = Index.mergeFileList(fileList, this.fileList);
      this.fileList.splice(this.fileList.length);
      if (isAllUpload) {
        this.$emit("change", this.fileList);
        this.isUploadStatus = false;
      }
    },
    handleDelete(file) {
      const index = Index.indexOfFile(this.fileList, file);
      this.fileList.splice(index, 1);
      this.$emit("deleteFile", file);
    },
    // handleProgress({ file, progress }) {
    //   console.log(`上传进度:${progress}   文件名称${file ? file.name : ""}`);
    // },
    // 查看图片
    handleClickImage(index = 0) {
      if (this.allImageList.length) {
        this.$viewImage({
          datas: this.allImageList,
          url: "url",
          thumUrl: "url",
          initialViewIndex: index
        });
      } else {
        this.$refs.preview.open();
      }
    },
    handleExceed() {
      const { name } = this.mdConfig;
      Message({ message: `字段（${name}）上传数量不能超过${this.limitNum}`, type: "error" });
    },
    /**
     * @description: 校验上传文件的数量
     * @param {*} len 即将上传的文件数量
     */
    checkLimitNum(len) {
      const { limitNumType, limitNum, name } = this.mdConfig;
      if (this.data.length === 1 && Number(limitNumType) === 2) { // 数量限制单个
        Message({
          type: "error",
          message: `字段（${name}）上传数量不能超过1`
        });
        return true;
      }
      if (Number(limitNumType) === 3) { // 自定义
        // const hasLen = this.data.length;
        const willLen = len;
        if (willLen > Number(limitNum)) {
          Message({
            type: "error",
            message: `字段（${name}）上传数量不能超过${limitNum}`
          });
          return true;
        }
      }
      return false;
    }
  },
  mounted() {

  }
};
</script>

<style scoped lang="scss">
.file-other {
  cursor: pointer;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #F5F8FC;
  border: 1px solid #E8ECF2;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #A9B5C6;
}
.annex {
  cursor: pointer;
  margin-left: 5px;
  width: 190px;
  height: 26px;
  background: #F5F8FC;
  border: 1px solid #E8ECF2;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .annex-icon {
    margin-left: 12px 0 11px;
    display: flex;
    align-items: center;
  }

  .annex-name {
    margin-left: 5px;
    width: 139px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1B1C1E;
    white-space: nowrap;
    /* 禁止文本换行 */
    overflow: hidden;
    /* 隐藏超出父元素宽度的文本 */
    text-overflow: ellipsis;
    /* 当文本溢出时显示省略号 */
  }
}

.image-list-item {
  width: 34px;
  height: 34px;
  background: #ECF1F7;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center
}

.border {
  border: 1px solid $field-border-color;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 32px;

  &:hover {
    border-color: $field-border-color-hover;
  }
}

.attachment-box {
  background-color: white;
  font-size: 12px;
  display: flex;
  align-items: center;

  //添加按钮
  .add-btn-box {
    margin: 0 5px;
    font-size: 12px;
  }

  .icon-box {
    // margin-left: 8px;
    display: flex;
  }

  //文件列表
  .files {
    display: flex;
    align-items: center;
    padding: 2px 2px;
  }

  &.fixedHeight {
    height: 32px;
  }
}
.logind-box {
    animation: loader 1000ms infinite linear;
    border-radius: 100%;
    border: 3px solid #3e90fe;
    border-left-color: transparent;
    color: transparent;
    display: inline-block;
    line-height: 1;
    width: 15px;
    vertical-align: middle;
    height: 15px;
}
@keyframes loader {
  0% {  transform: rotate(0deg);  }
  100% {transform: rotate(360deg);}
}
</style>
<style>
.more-list {
  padding: 5px 0;
}
</style>
