<!--
 * @Description: 关联显示的弹窗
 * @Author: 梁平贤
 * @Date: 2020/7/23 11:49
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-12 14:01:20
 -->
<template>
  <en-dialog
    v-if="dialogVisible"
    :visible="dialogVisible"
    title="关联数据显示"
    width="1000px"
    en-body-scroll
    append-to-body
    @close="close"
  >
    <div slot="title" class="custom-title">
      <span>关联数据显示</span>
      <div>
        <en-icon name="iconbus-btn1_1" :style="{marginRight: column.codeCheckIn === 0 ? '20px' : '30px'}"
          v-if="canManualAdd" color="#26C393" @click.native="checkCanAdd" size="small" title="手工新增"></en-icon>
        <en-icon name="icondaochu" v-if="column.codeCheckIn === 0" color="#26C393" @click.native="exportRelationData" size="small" title="导出"></en-icon>
      </div>
    </div>
    <en-table
      class="table"
      ref="enTable"
      :loading="loading"
      :height="500"
      :data="records"
      :page-config="{ pageModel, changeMethod: handlePageChanged }"
      column-min-width="150"
      @row-click="handleRowClick"
      @filter-sort-change="handleFilterSortChange"
    >
      <en-table-column v-if="canExpand" type="expand" title="展开" width="50">
        <template v-slot="{ row }">
          <sub-table :row-data="row" :column="column"></sub-table>
        </template>
      </en-table-column>
      <en-table-column type="index" width="50" align="center"></en-table-column>
      <en-table-column align="center"  v-for="(col, index) in showContent" :key="col.id || index" :data="col"></en-table-column>
      <!-- <table-column v-for="(col, index) in showContent"
        is-home-page
        :attachment-sortable="true"
        :key="col.id || index" :data="col"
      >
      </table-column> -->
      <template #paginationLeft>
        <div class="field-statistics" >
          <div v-for="(value, name) of fieldStatistics" :key="name" class="statistics-item">
            <strong>
              {{name == 2 ? "全部" : "本页"}} :
            </strong>
            <span class="content" v-for="item in value" :key="item.id">
              {{item.statisticsShow}}: {{item.value}}
            </span>
          </div>
        </div>
      </template>
    </en-table>
    <!-- <div slot="footer">
      <en-button @click="close">
        确定
      </en-button>
    </div> -->
  </en-dialog>
</template>

<script>
import { groupBy } from "lodash";
import { detailListService } from "@/api/businessModel";
// import tableColumn from "@/components/en-table-extra/tableColumn";
import { newTabReadonlyBusinessData } from "@/components/businessDetail/helper.js";
import { BusinessType, BusinessFieldType, BusinessRelationType } from "@/components/businessDetail/data/BusinessCommonHeader.js";
import MixinRelation from "@/components/businessDetail/components/columnRendering/components/relevancyAdd/MixinRelation";
import SubTable from "./SubTable";

export default {
  name: "AssociateShowDialog",
  mixins: [MixinRelation],
  components: {
    // tableColumn,
    SubTable
  },
  props: {
    dialogVisible: Boolean,
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 明细第几行,关联数据要用
    rowIndex: {
      type: Number,
      default: 0
    },
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      records: [],
      tableHeight: "",
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null
      },
      fieldStatistics: {}
    };
  },
  computed: {
    canExpand() {
      const { showListStyle, selAreaType } = this.column;
      return showListStyle === 2 && selAreaType !== "" && selAreaType !== undefined && selAreaType !== 1 && selAreaType !== 4;
    },
    canManualAdd() {
      return this.column.allowAddFlag === 0 && this.column.showModel === 1;
    },
    showContent() {
      const { relationType } = this.column;
      let { showContent } = this.column || [];
      const { showListStyle, areaShowField } = this.column;
      if (relationType === BusinessRelationType.form) {
        showContent = showContent.length ? showContent : [{ field: "barCode", fieldType: "1", name: "编号" }, { field: "message", fieldType: "1", name: "审批概要" },
          { field: "requserName", fieldType: "1", name: "申请人" }];
      } else if (relationType === BusinessRelationType.basic) {
        showContent = showContent.length ? showContent : [{ field: "name", fieldType: "1", name: "名称" }, { field: "code", fieldType: "1", name: "编号" }];
      }
      if (showListStyle === 2) {
        showContent = showContent.filter((field) => field.areaShowField === areaShowField || !field.areaShowField);
      }
      // 过滤掉明细
      return showContent.filter((field) => Number(field.fieldType) !== BusinessFieldType.detailParent && (Number(field.listIsShow) === 0 || field.listIsShow === undefined));
    }
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.queryNewRelationDataList();
      }
    }
  },
  methods: {
    businessDetail_baseData(event) {
      if (event && event.key === "businessDetail_baseData") {
        this.queryNewRelationDataList();
        window.removeEventListener("storage", this.businessDetail_baseData);
      }
    },
    businessDetail_businessData(event) {
      // FIXME:刷新页面 移除监听. 页面销毁也需要移除, 当前的弹窗实现方式没有销毁页面. 建议在最外层v-if下
      if (event && event.key === "businessDetail_businessData") {
        this.queryNewRelationDataList();
        // 新增业务建模 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_businessData);
      }
    },
    exportRelationData() {
      // 关联显示
      if (!this.pageModel.total) {
        this.$message.warning("当前无数据");
        return;
      }
      if (this.column.showModel === 1) {
        this.exportRelationDataByView();
      } else {
        this.exportRelationDataByAdd({
          ids: this.pageModel.records.map((item) => item.id).join(",")
        });
      }
    },
    handleRowClick({ row, column: tableColumn }) {
      if (tableColumn.type === "expand") {
        return;
      }
      // 穿透 addBy:lpx 2020-12-24
      if (this.column.relationType === BusinessRelationType.business) { // 业务建模
        newTabReadonlyBusinessData({ templateId: row.templateId || this.column.templateId || this.column.relationData, id: row.mainId || row.id }, row.type);
      } else { // 基础数据
        newTabReadonlyBusinessData({ objectTypeId: this.column.relationData, businessType: BusinessType.pureBasic, id: row.mainId || row.id }, 3);
      }
    },
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      this.pageModel.pageNo = 1;
      this.pageModel.filterFields = filterData;
      this.pageModel.sortField = sortData;
      this.queryNewRelationDataList();
    },
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      this.queryNewRelationDataList();
    },
    async queryNewRelationDataList() {
      const {
        field, version, relationType, relationData, templateDataId, relationCondition, relationRange, showModel
      } = this.column;
      const templateId = this.column.templateId || this.$route.query.templateId;
      const ids = this.businessData.originData.mdData[`${this.column.field}_relationData`]?.map((item) => item.detailId || item.id).join(",");
      if (!this.showContent.length) {
        this.$message.warning("未配置显示字段，请联系管理员");
        return;
      }
      const mdData = JSON.parse(this.businessData.paramsForSubmit().mdData);
      const { originData } = this.businessData.mdData;
      const relationSet = relationCondition.reduce((acc, item) => {
        acc[item.targetField] = item.tparentField && mdData[item.tparentField][this.rowIndex] ? mdData[item.tparentField][this.rowIndex][item.targetField]
          : mdData[item.targetField] || originData[item.targetField];
        return acc;
      }, {});
      this.relationSet = relationSet;
      this.loading = true;
      const commParams = {
        relationSet,
        controlField: field,
        controlVersion: version,
        controlTempId: templateId
      };
      const {
        records,
        pageNo,
        pageSize,
        total,
        totalPages,
        commParams: { fieldStatistics }
      } = relationType === BusinessRelationType.basic ? await detailListService.mdQueryNewRelationDataList({
        ...this.pageModel,
        ids,
        relationRange,
        relationType,
        onlyFilterById: ids?.length ? 0 : null,
        templateId: relationData,
        controlType: 1,
        screen: ids ? 4 : null,
        commParams: JSON.stringify(commParams)
      }) : await detailListService.mdQuerySubDataList({
        ...this.pageModel,
        ids,
        controlType: 1,
        controlTempId: templateId,
        controlField: field,
        controlVersion: version,
        onlyFilterById: ids?.length ? 0 : null,
        parentId: 0,
        schemeVersionId: "",
        assignFlag: 2,
        templateId: relationData || templateDataId,
        area: 1,
        commonSet: JSON.stringify({ relationSet })
      });
      this.loading = false;
      if (fieldStatistics) {
        this.fieldStatistics = groupBy(fieldStatistics, "statisticsScope");
      }
      // 关联显示查全部
      const shouldShowAll = ids || Number(showModel) === 1 || showModel === undefined;
      this.records = shouldShowAll ? records : [];
      Object.assign(this.pageModel, {
        pageNo,
        pageSize,
        totalPages: shouldShowAll ? totalPages : 0,
        total: shouldShowAll ? total : 0
      });
      // 只有一条进入详情  因为弹窗也有关联添加暂时去掉
      if (records?.length === 1 && !this.pageModel.filterFields && this.pageModel.pageNo === 1 && !this.canManualAdd) {
        // const { relatedDataType, templateId, relationData } = this.column;
        // newTabReadonlyBusinessData({
        //   templateId,
        //   businessType: relatedDataType === 1 ? BusinessType.pureBasic : BusinessType.business,
        //   objectTypeId: relationData,
        //   id: records[0].id
        // });
        const row = records[0];
        if (this.column.relationType === BusinessRelationType.business) { // 业务建模
          newTabReadonlyBusinessData({ templateId: row.templateId || this.column.templateId || this.column.relationData, id: row.mainId || row.id }, row.type);
        } else { // 基础数据
          newTabReadonlyBusinessData({ objectTypeId: this.column.relationData, businessType: BusinessType.pureBasic, id: row.mainId || row.id }, 3);
        }
        this.close();
      }
      const { filterFields, sortField } = this.pageModel;
      let distance = 85;
      if ((filterFields && filterFields.length > 2) || (sortField && sortField.length > 2)) {
        distance = 135;
      }
      this.$nextTick(() => {
        this.tableHeight = document.querySelector(".el-dialog__body")?.getBoundingClientRect().height - distance;
      });
    },
    close() {
      this.$emit("update:dialogVisible", false);
    }
  }
};
</script>
<style lang="scss">
.el-dialog {
  .custom-title {
    display: flex;
    justify-content: space-between;
    .en-icon {
      margin: 2px 30px 0 0;
      cursor: pointer;
    }
  }
  .field-statistics {
    padding-left: 10px;
    font-size: 12px;
    .statistics-item {
      margin-right: 20px;
      color: #606060;
      strong {
        margin-right: 8px;
      }
      span {
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}
</style>
