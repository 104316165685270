<template>
  <div class="sub-table">
    <div v-if="level === 2" class="header">{{ title }}</div>
    <en-table
      ref="table"
      @cell-dblclick="handleRowDblClick"
      :loading="loading"
      :data="showDatas"
      :page-config="pageConfig"
      :height-config="{ bottomHeight: 10 }"
      :span-method="spanMethod"
      :select-config="{ reserve: true }"
      @select-change="handleSelectChange"
      @filter-sort-change="handleColumnFilterChange"
    >
      <en-table-column type="index-selection" v-if="isMutiple" fixed="left" prop="selection" width="50"></en-table-column>
      <en-table-column type="radio" v-else prop="selection" fixed="left" width="50">
        <template v-slot:header="{  }">
          <span>勾选</span>
        </template>
      </en-table-column>
      <table-column v-for="(col, index) in showList"  :key="col.id || index" :data="col"></table-column>
      <template #paginationLeft>
        <div class="field-statistics" v-if="!selections.length">
          <div v-for="(value, name) of fieldStatistics" :key="name" class="statistics-item">
            <strong>
              {{name == 2 ? "全部" : "本页"}} :
            </strong>
            <span class="content" v-for="item in value" :key="item.id">
              {{item.statisticsShow}}: {{item.value}}
            </span>
          </div>
        </div>
        <div v-else-if="level === 1" class="footer-left">
          <div class="label">已选<span class="count">{{ selections.length }}</span>条数据</div>
          <div class="clear" v-if="isMutiple" @click="clearSelection">清空</div>
        </div>
      </template>
      <template #pagination>
        <div v-if="checkRowIds.length && isMutiple">
          <en-button style="margin-left: 10px;" type="green" v-if="!loading && level === 1" @click="confirmAdd(1)">
            <el-tooltip effect="dark" content="此次选择的数据累加在原有数据后方" placement="bottom-start">
              <span>依次新增</span>
            </el-tooltip>
          </en-button>
          <en-button v-if="!loading && level === 1" @click="confirmAdd(2)">
            <el-tooltip effect="dark" content="原有数据被删除，此次选择的数据将替代原有数据" placement="bottom-start">
              <span>覆盖新增</span>
            </el-tooltip>
          </en-button>
        </div>
        <en-button style="margin-left: 10px;" v-else @click="confirmAdd(isMutiple ? 1 : 2)">
          <span>确定</span>
        </en-button>
      </template>
    </en-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { groupBy } from "lodash";
import { newTabAddBusinessData } from "@/components/businessDetail/helper";
import { detailListService } from "@/api/businessModel";
import { BusinessFieldType, AssignmentOptions, BusinessType } from "@/components/businessDetail/data/BusinessCommonHeader";
// import TableColumn from "@/components/en-table-extra/tableColumn";
import API from "@/api/businessModel/list";
import { Message } from "element-ui";
import mixinRelation from "./MixinRelation";

export default {
  name: "subTable",
  mixins: [mixinRelation],
  components: {
    TableColumn: () => import("@/components/en-table-extra/tableColumn")
  },
  props: {
    parentData: {
      type: Object
    },
    column: {
      type: Object,
      require: true
    },
    level: {
      type: Number,
      default: 1
    },
    businessData: {
      type: Object
    },
    // 手动关联添加
    isManualAddRelation: {
      type: Boolean,
      default: false
    },
     // 明细第几行
     rowIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      showDatas: [],
      showList: [],
      title: "",
      subShowInfo: null,
      hasClear: false,
      fieldStatistics: {},
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null,
        data: null
      },
      selections: []
    };
  },
  mounted() {
    this.loading = false;
    if (this.parentData?.children) {
      this.showDatas = this.parentData?.children;
      this.updateShowInfo();
      return;
    }
    this.queryRbacGloableVariableList();
    this.queryData();
  },
  computed: {
    ...mapState("businessModel", ["dateVariables"]),
    // 是否允许多行
    isMutiple() {
      return this.column.multipleFlag === 0;
    },
    checkRowIds() {
      return !this.isManualAddRelation ? this.column.columnValue?.map((item) => item.id) : [];
    },
    // 分页
    pageConfig() {
      return {
        pageModel: this.pageModel,
        changeMethod: this.handlePageChanged
      };
    },
    // 层级
    levelCount() {
      if (Number(this.column.showListStyle) === 1) {
        return 1;
      }
      return Number(this.column.selAreaType);
    },
    // 列头显示字段
    showContent() {
      if (this.parentData) {
        return this.parentData?.showInfo?.colList;
      }
      const {
        showContent, selAreaField, selAreaParentField
      } = this.column;
      // eslint-disable-next-line no-unused-vars
      const { associatedData, detailParent, subSet } = BusinessFieldType;
      // eslint-disable-next-line no-unused-vars
      let field = "mainTopArea";
      if (this.level === 2) {
        field = selAreaParentField || selAreaField;
      } else if (this.level === 3) {
        field = selAreaField;
      }
      const List = showContent?.filter((item) => item.listIsShow === "0" && ![associatedData, detailParent, subSet].includes(Number(item.fieldType)));
      console.log("List", List);
      if (List.length === 0) {
        Message({
          showClose: true,
          message: "没有配署显示字段，请联系管理员",
          type: "info"
        });
      }
      return List;
      // return showContent?.filter((item) => item.listIsShow === "0" && item.areaShowField === field);
    }
  },
  methods: {
    ...mapMutations("businessModel", ["saveDateVariables"]),
    async queryRbacGloableVariableList() {
      if (!this.dateVariables.length) {
        const variableList = await API.queryRbacGloableVariableList({ type: 3 });
        this.saveDateVariables(variableList);
      }
    },
    handleRowDblClick({ row }) {
      const { column } = this;
      let url;
      if (column.relationType === 1) { // 表单
        url = `/toBillDetails.do?mainId=${ row.id}`;
      } else if (column.relationType === 3) { // 业务对象
        return;
      } else if (column.relationType === 4) { // 业务建模
        if (column.relationData === "999999999") {
          url = `/toInvoiceDetail.do?invoiceId=${ row.id}`;
        }
        newTabAddBusinessData({ templateId: column.templateId || column.relationData, id: row.id }, row.type);
      } else { // 基础数据
        newTabAddBusinessData({ objectTypeId: column.relationData, businessType: BusinessType.pureBasic, id: row.id }, 3);
      }
      console.log(url);
    },
    clearSelection() {
      this.hasClear = true;
      this.selections = [];
      this.$refs.table.setAllSelection(false);
    },
    handleSelectChange({ selection }) {
      this.selections = selection;
    },
    // 合并展开行
    spanMethod({ columnIndex }) {
      if (this.level === 2) {
        if (!this.subShowInfo) {
          return [1, 1];
        }
        if (columnIndex === this.showList.length + 1) {
          return {
            rowspan: 1,
            colspan: 2
          };
        }
        if (columnIndex === this.showList.length + 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      if (this.level === 1) {
        if (columnIndex === this.showList.length) {
          return {
            rowspan: 1,
            colspan: 2
          };
        }
        if (columnIndex === this.showList.length + 1) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return [1, 1];
    },
    /**
     * 查询数据
     */
    queryData() {
      this.loading = true;
      const {
        relationRange, relationType, relationData, nameVariable, version, templateId, objectTypeId, field, relationCondition
      } = this.column;
      const mdData = JSON.parse(this.businessData.paramsForSubmit().mdData);
      const { originData } = this.businessData.mdData;
      const relationSet = relationCondition.reduce((acc, item) => {
        acc[item.targetField] = item.tparentField && mdData[item.tparentField]?.[this.rowIndex] ? mdData[item.tparentField][this.rowIndex][item.targetField]
          : mdData[item.targetField] || originData[item.targetField];
        return acc;
      }, {});
      const commParams = {
        relationSet,
        controlField: nameVariable || field,
        controlVersion: version || 0,
        controlTempId: templateId || objectTypeId || this.$route.query.templateId || this.$route.query.objectTypeId
      };
      const params = {
        relationRange,
        relationType,
        templateId: relationData,
        // 先写死
        controlType: 1,
        commParams: JSON.stringify(commParams)
      };
      const handleCallback = (res) => {
        this.loading = false;
        this.fieldStatistics = groupBy(res.commParams.fieldStatistics, "statisticsScope");
        this.updateShowInfo();
        this.showDatas = res.records;
        this.pageModel = { ...res };
        function removeRepeatByKey(arr, key = "id") {
          const idMap = {};
          const result = [];
          arr.forEach((item) => {
            if (!idMap[item[key]]) {
              idMap[item[key]] = item;
              result.push(item);
            }
          });
          return result;
        }
        if (!this.hasClear) {
          this.selections = removeRepeatByKey([...this.selections, ...this.showDatas.filter((item) => this.checkRowIds.find((id) => item.id === id))]);
          this.$refs.table.setSelection(this.selections, true);
          this.$refs.table.setRadioRow(this.selections[0]);
        }
      };
      const { data, records, ...rest } = this.pageModel;
      // 基础数据或不是手动关联添加都走这个接口
      if (Number(relationType) === 2 || !this.isManualAddRelation) {
        detailListService.mdQueryNewRelationDataList({
          ...rest,
          ...params
        }).then((res) => {
          handleCallback(res);
        }).catch(() => {
          this.loading = false;
        });
      } else if (Number(relationType) === 4) {
        // 业务建模
        detailListService.mdQuerySubDataList({
          ...rest,
          ...commParams,
          commonSet: JSON.stringify({ relationSet }),
          ids: "",
          parentId: "0",
          area: 1
        }).then((res) => {
          handleCallback(res);
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    /**
     * 更新列头
     */
    updateShowInfo() {
      this.showList = this.level === 1 ? this.showContent : this.parentData.showInfo.colList;
      this.title = this.parentData?.showInfo?.name;
      if (this.parentData) {
        this.subShowInfo = this.parentData.subShowInfo;
      }
    },
     // 筛选
    handleColumnFilterChange({ filterData, sortData }) {
      this.pageModel.pageNo = 1;
      if (filterData) {
        filterData = JSON.parse(filterData);
        filterData.forEach((field) => {
        // 多选是模糊搜索
          if (Number(field.fieldType) === BusinessFieldType.multiSelect) {
            field.value = field.valueText;
          }
        });
      }
      this.pageModel.filterFields = filterData ? JSON.stringify(filterData) : null;
      this.pageModel.sortField = sortData;
      this.queryData();
    },
    // page事件
    handlePageChanged(data) {
      Object.assign(this.pageModel, data);
      this.queryData();
    },
    // 弹窗确定
    confirmAdd(type) {
      if (!this.selections.length) {
        this.$message.warning("请选择关联数据");
        return;
      }
      this.$emit("complete");
      if (!this.isManualAddRelation) {
        // 关联添加控件
        this.$eventHub.$emit("confirmAdd", {
          type,
          field: this.column.field,
          selection: this.selections
        });
        if (type === 1) {
          this.column.columnValue = this.column.columnValue.concat(this.selections);
        }
        if (type === 2) {
          this.column.columnValue = this.selections;
        }
        this.column.columnValue = [...this.column.columnValue].map((item) => {
          const copyItem = { ...item };
          delete copyItem.index;
          delete copyItem["$selection-checked"];
          return copyItem;
        });
        this.column.updateValueWithOptions(this.column.columnValue, AssignmentOptions.manual);
        this.column.triggerInputLimitWatch = Math.random();
        this.fillRelationField();
      } else if (this.selections.length) {
        // 手动关联添加
        if (this.column.multipleFlag === 1) {
          this.fillRelationMultiField([this.selections[0]]);
        } else {
          this.fillRelationMultiField(this.selections);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.sub-table {
  tbody {
    display: table;
  }
  .el-icon-circle-check {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
/deep/.vxe-header--row .is--firstCol{
  background-color: #F2F5F8;
}
  /deep/.vxe-table--header-wrapper{
    background-color: #F2F5F8;
  }
.sub-table {
  background-color: white;
  border-bottom: 1px solid #e8ecf2;
  .field-statistics {
    padding-left: 10px;
    font-size: 12px;
    .statistics-item {
      margin-right: 20px;
      color: #606060;
      strong {
        margin-right: 8px;
      }
      span {
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
  .header {
    color: #333333;
    font-size: 12px;
    line-height: 32px;
    font-weight: bold;
  }
  .assist-btn {
    position: absolute;
    right: 10px;
    bottom: 11px;
  }
  .footer-left {
    display: flex;
    line-height: 0;
    font-size: 12px;
    .count {
      color: #3e90fe;
    }
    .label {
      color: #636C78;
    }
    .clear {
      color: #3e90fe;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .click-class {
    display: flex;
    line-height: 32px;
    position: relative;
    width: 93px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    .bg {
      position: absolute;
      width: 93px;
      height: 32px;
      opacity: 0.13;
      background: #26C393;
    }
    .en-icon {
      width: 14px;
      height: 14px;
      margin-left: 12px;
      color: #26C393;
    }
    .text {
      display: flex;
      align-items: center;
      width: 48px;
      height: 12px;
      color: #26C393;
      font-weight: 400;
      font-size: 12px;
      margin-left: 6px;
    }
  }
  & .sub-table-level1 {
    padding: 0 10px 10px;
    background-color: white;
  }
  & .sub-table-level2 {
    padding: 10px;
    background-color: #DCE6EF;
  }
}
</style>
